const screens = {
  App: {
    segmenationFilter: 'Spanish',
    bulkActions: 'Spanish',
    imports: 'Spanish',
    exports: 'Spanish',
    jobsIndicator: 'Spanish',
    imagesUploader: 'Spanish',
    productVariants: 'Spanish',
  },
};

const components = {
  // bulk-actions
  BulkActions: {
    confirmApplyBulkAction: 'Spanish',
    confirmNoRecordsSelected: 'Spanish',
    disabled: 'Spanish',
    apply: 'Spanish',
  },
  // exports
  Exports: {
    sensitiveWarning: 'Spanish',
    runConfirmation: 'Spanish',
    errorCreatingExport: 'Spanish',
    sensitive: 'Spanish',
    exports: 'Spanish',
    export: 'Spanish',
    cancel: 'Spanish',
    chooseExportType: 'Spanish',
    name: 'Spanish',
    optional: 'Spanish',
    ie: 'Spanish',
    columns: 'Spanish',
    options: 'Spanish',
    downloadExampleFile: 'Spanish',
  },
  // images-uploader
  CompactFilesList: {
    failed: 'Spanish',
  },
  ImagesUploader: {
    confirmDeleteImages: 'Spanish',
    dragDropFiles: 'Spanish',
    browse: 'Spanish',
    delete: 'Spanish',
    deselectAll: 'Spanish',
    selectAll: 'Spanish',
    errorUploadingMediaFile: 'Spanish',
  },
  Instructions: {
    minWidth: 'Spanish',
    minHeight: 'Spanish',
    maxWidth: 'Spanish',
    maxHeight: 'Spanish',
    maxImages: 'Spanish',
    collapse: 'Spanish',
    expand: 'Spanish',
    imageInstructions: 'Spanish',
    imageRestrictions: 'Spanish',
  },
  Image: {
    selected: 'Spanish',
    view: 'Spanish',
    delete: 'Spanish',
  },
  // imports
  Imports: {
    confirmRunImport: 'Spanish',
    errorCreatingImport: 'Spanish',
    imports: 'Spanish',
    import: 'Spanish',
    cancel: 'Spanish',
    chooseImportType: 'Spanish',
    name: 'Spanish',
    optional: 'Spanish',
    ie: 'Spanish',
    errorUploadingImportFile: 'Spanish',
    options: 'Spanish',
    help: 'Spanish',
    downloadExampleFile: 'Spanish',
  },
  // jobs-indicator
  Job: {
    cancelled: 'Spanish',
    complete: 'Spanish',
    failed: 'Spanish',
    statusUnknown: 'Spanish',
    download: 'Spanish',
    errors: 'Spanish',
    last: 'Spanish',
    serverError: 'Spanish',
  },
  JobsIndicator: {
    importFinishedRefresh: 'Spanish',
    refresh: 'Spanish',
    imports: 'Spanish',
    exports: 'Spanish',
    noRecentImportsExports: 'Spanish',
  },
  // product-variants
  BulkSetInventoryModal: {
    bulkSetInventoryLevels: 'Spanish',
    cancel: 'Spanish',
    set: 'Spanish',
    inventoryLevels: 'Spanish',
  },
  BulkSetPriceModal: {
    bulkSetPrice: 'Spanish',
    cancel: 'Spanish',
    set: 'Spanish',
    price: 'Spanish',
  },
  OptionRow: {
    variantDeletionDisclaimer: 'Spanish',
    optionDeletionDisclaimer: 'Spanish',
    optionNamePlaceholder: 'Spanish',
    optionValuesPlaceholder: 'Spanish',
    delete: 'Spanish',
  },
  ProductVariants: {
    productVariants: 'Spanish',
    productOptions: 'Spanish',
    addOption: 'Spanish',
    img: 'Spanish',
    inventory: 'Spanish',
    price: 'Spanish',
    sku: 'Spanish',
    addVariant: 'Spanish',
  },
  RenameOptionValueModal: {
    bulkSetInventoryLevels: 'Spanish',
    cancel: 'Spanish',
    set: 'Spanish',
    rename: 'Spanish',
  },
  VariantRow: {
    areYouSure: 'Spanish',
    inventoryPlaceholder: 'Spanish',
    pricePlaceholder: 'Spanish',
    skuPlaceholder: 'Spanish',
    delete: 'Spanish',
  },
  VariantsSelectBar: {
    confirmDeleteVariants: 'Spanish',
    select: 'Spanish',
    setInventory: 'Spanish',
    setPrice: 'Spanish',
    delete: 'Spanish',
    clear: 'Spanish',
    by: 'Spanish',
  },
  // segmentation-filter
  AddFilter: {
    addNewFilter: 'Spanish',
  },
  FilterRow: {
    type: 'Spanish',
    loadingOptions: 'Spanish',
  },
  FiltersOperator: {
    and: 'Spanish',
    or: 'Spanish',
  },
  LoadSegmentation: {
    confirmLoadUnsaved: 'Spanish',
    loadSegment: 'Spanish',
  },
  SegmentationFilter: {
    nameRequired: 'Spanish',
    errorSavingSegmentation:'Spanish',
    provideUniqueName: 'Spanish',
    confirmDeleteSegmentation: 'Spanish',
    confirmCancel: 'Spanish',
    confirmClear: 'Spanish',
    newSegment: 'Spanish',
    segmentName: 'Spanish',
    delete: 'Spanish',
    addFilter: 'Spanish',
    clear: 'Spanish',
    loadSegment: 'Spanish',
    save: 'Spanish',
    saveAsNew: 'Spanish',
    addSomeFilters: 'Spanish',
    unsavedFilters: 'Spanish',
    cancel: 'Spanish',
    apply: 'Spanish',
  },
  Utils: {
    equalTo: 'Spanish',
    notEqualTo: 'Spanish',
    contains: 'Spanish',
    doesNotContain: 'Spanish',
    oneOf: 'Spanish',
    notOneOf: 'Spanish',
    greaterThan: 'Spanish',
    lessThan: 'Spanish',
    greaterThanOrEqualTo: 'Spanish',
    lessThanOrEqualTo: 'Spanish',
    hasAnyOf: 'Spanish',
    hasAllOf: 'Spanish',
  },
};

export default ({
  ...screens,
  ...components,
});
