import React, { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import downloadIcon from '../../images/download2.svg';
import importIcon from '../../images/file-import.svg';
import exportIcon from '../../images/file-export.svg';
import cancelledIcon from '../../images/times-circle.svg';
import completeIcon from '../../images/check-circle.svg';
import failedIcon from '../../images/exclamation-circle.svg';
import unknownIcon from '../../images/question-circle.svg';
import moment from 'moment-timezone';
import { strings } from '../../localization/localization';

function Job({ job, utils }) {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const runningIndicator = (percentComplete) => (
    <CircularProgressbar
      value={job.percent_complete}
      strokeWidth={6}
    />
  );
  const cancelledIndicator = () => (<img src={cancelledIcon} alt={strings.Job.cancelled} />);
  const completeIndicator = () => (<img src={completeIcon} alt={strings.Job.complete} />);
  const failedIndicator = () => (<img src={failedIcon} alt={strings.Job.failed} />);
  const unknownIndicator = () => (<img src={unknownIcon} alt={strings.Job.statusUnknown} />);

  let progressIndicator = (job) => {
    switch (true) {
      case utils.isRunning(job.status):
        return runningIndicator(job.percent_complete);
      case utils.isCancelled(job.status):
        return cancelledIndicator();
      case utils.isComplete(job.status):
        return completeIndicator();
      case utils.isFailed(job.status):
        return failedIndicator();
      default:
        return unknownIndicator();
    }
  };

  const typeIcon = job.type === 'IMPORT' ? importIcon : exportIcon;
  const hasDetails = job.errors.length > 0;
  const hasDetailsClass = hasDetails ? 'has-details' : '';

  return (
    <>
      <tr
        className={`montserrat-medium montserrat-14 job ${job.type.toLowerCase()} ${job.status.toLowerCase()} ${hasDetailsClass}`}
        key={`${job.type}${job.name}`}
        onClick={() => {
            if (hasDetails) {
              setDetailsOpen(!detailsOpen);
            }
        }}
      >
        <td className="type-icon">
          <div className="inner">
            <img src={typeIcon} alt={job.type.toLowerCase()} />
          </div>
        </td>
        <td className="name">
          <div className="inner">
            {job.name} <span className="time">{moment(job.updated_at).fromNow()}</span>
            {job.type === 'EXPORT' && utils.isComplete(job.status) && !utils.isCancelled(job.status) && !!job.download_url && (
              <a href={job.download_url} title={strings.Job.download} className="download">
                <img src={downloadIcon} alt={strings.Job.download} />
              </a>
            )}
          </div>
        </td>
        <td className="progress">
          <div className="inner">
            {progressIndicator(job)}
          </div>
        </td>
      </tr>
      {detailsOpen && hasDetails && (
        <tr className="details styled-form">
          <td/>
          <td colspan="2">
            <h4 className="raleway-semi-bold raleway-12 errors-heading">
              {strings.Job.errors} <span className="subtext">{strings.Job.last} {Math.min(job.errors.length, 5)}</span>
            </h4>
            <div className="errors">
              {job.errors.slice(-5).map(error => {
                const title = error.type === 'EXCEPTION' ? `${error.message} - ${error.stacktrace}` : '';
                const exceptionClass = !!title ? 'exception' : '';
                let msg = '';
                switch(error.type) {
                  case 'VALIDATION_EXCEPTION':
                  case 'GENERAL':
                    msg = `${String.fromCharCode(183)} ${error.message}`;
                    break;
                  default:
                    msg = `${String.fromCharCode(183)} ${strings.Job.serverError}`;
                    break;
                }

                return (
                  <div title={title} className={`montserrat-medium montserrat-12 job-error ${exceptionClass}`}>
                    {msg}
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default Job;
