import $ from 'jquery';
import _ from 'lodash';

const isFormInput = ($element) => {
  return $element.length && ['INPUT','SELECT','TEXTAREA'].indexOf($element[0].tagName) !== -1;
}

export const isDev = () => process.env.NODE_ENV === 'development';

// TODO Clean up this messy mixture of ways to get JSON data from HTML elements
export const getJSONData = (selectorOrEl, defaultData = {}) => {
  const element = $(selectorOrEl);
  let dataJsonFound = false;
  let inputJsonFound = false;
  let data = {};

  let dataJsonObj = element.data('json');
  if (_.isObject(dataJsonObj)) {
    data = { ...dataJsonObj };
    dataJsonFound = true;
  }

  const formInputJson = isFormInput(element) ? element.val() : null;

  if (formInputJson && _.isString(formInputJson)) {
    try {
      const inputJsonObj = JSON.parse(formInputJson);

      if (_.isObject(inputJsonObj)) {
        data = { ...data, ...inputJsonObj };
        inputJsonFound = true;
      }
    } catch(e) {
      console.log('Invalid JSON:', formInputJson)
    }
  }

  if (!data.context) {
    data.context =  element.data('context');
  }

  if (!dataJsonFound && !inputJsonFound) {
    return defaultData;
  }

  return data;
}

export const setJSONData = (selector, value) => {
  const element = $(selector);

  if (isFormInput(element)) {
    element.val(JSON.stringify(value));
  } else {
    element.data('json', JSON.stringify(value));
  }
}

export const titleCase = (str) => {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}
