import React from 'react';

function Checkbox({ checked, name, value, disabled, onChange, ...rest }) {
  return (
    <div className={`styled-checkbox ${checked ? 'checked':''}`}>
      <input
        type="checkbox"
        {...rest}
        checked={checked}
        name={name}
        value={value}
        onChange={e => {
          if (disabled) return;

          onChange(e);
        }}
        disabled={disabled}
      />
    </div>
  );
}

Checkbox.defaultProps = {
  checked: false,
  name: '',
  value: '',
  disabled: false,
  onChange: () => {}
};

export default Checkbox;
