import { isDev } from '../../utils';
import axios from 'axios';
import _ from 'lodash';
import { makeImage } from './utils';

export const saveMediaItem = async (url, serverId, dispatch) => {
  const savedImage = await axios({
    method: 'POST',
    // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
    url,
    crossDomain: true,
    data: {
      filepond_server_id: serverId
    },
    timeout: isDev() ? 0 : 30000,
  })
  .then(({ data }) => {
    // handle success
    return data.image;
  })
  .catch(error => {
    // handle error
    console.log(error);
  });

  if (savedImage) {
    dispatch({
      type: 'ADD_IMAGES',
      payload: {
        images: [makeImage(savedImage)]
      }
    });
  }

  return savedImage;
}

export const saveMediaItemS3 = async (url, imageMediaId, filename, dispatch) => {
  const savedImage = await axios({
    method: 'POST',
    // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
    url,
    crossDomain: true,
    data: {
      image_media_id: imageMediaId,
      filename,
      // sizes: []
    },
    timeout: isDev() ? 0 : 30000,
  })
  .then(({ data }) => {
    // handle success
    return data.data;
  })
  .catch(error => {
    // handle error
    console.error(error);
  });

  if (savedImage) {
    dispatch({
      type: 'ADD_IMAGES',
      payload: {
        images: [makeImage(savedImage)]
      }
    });
  }

  return savedImage;
}

const makeCancelToken = () => axios.CancelToken.source();

const trackRequest = (cancelToken, currentSaveRequest) => {
  currentSaveRequest.current = cancelToken
};

const cancelRequest = (currentSaveRequest) => {
  if (currentSaveRequest.current) {
    currentSaveRequest.current.cancel('Interrupted by user.');
  }
};

export const saveImages = _.debounce(async (url, newImages, dispatch, currentSaveRequest) => {
  dispatch({
    type: 'HYDRATE',
    payload: {
      saving: true
    }
  });

  const cancelToken = makeCancelToken();

  cancelRequest(currentSaveRequest);
  trackRequest(cancelToken, currentSaveRequest);

  const images = newImages.map(({
    image: { image_media_id = null, sort_order = 0, caption = null },
    server_id = null
  }) => ({
    image_media_id, // OR
    server_id,

    sort_order,
    caption,
  }));

  if (isDev()) {
    // Fake server response
    console.log('Saved', images);
  } else {
    await axios({
      method: 'PUT',
      // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
      url,
      crossDomain: true,
      data: {
        images
      },
      timeout: isDev() ? 0 : 30000,
      cancelToken: cancelToken.token,
    })
    .then(({ data }) => {
      // handle success
      return data;
    })
    .catch(error => {
      // handle error
      console.log(error);
    });
  }

  dispatch({
    type: 'HYDRATE',
    payload: {
      saving: false
    }
  });
}, 750);
