import React from 'react';
import { strings } from '../../localization/localization';

function FiltersOperator({ state, dispatch }) {
  const setFiltersOperator = (operator) => {
    dispatch({
      type: 'SET_FILTERS_OPERATOR',
      payload: operator
    })
  }

  if (!state.activeFilters.length) return null;

  return (
    <div
      className="filters-operator"
    >
      <button
        onClick={() => setFiltersOperator('AND')}
        className={state.operator === 'AND' ? 'active' : ''}
      >
        {strings.FiltersOperator.and}
      </button>
      <button
        onClick={() => setFiltersOperator('OR')}
        className={state.operator === 'OR' ? 'active' : ''}
      >
        {strings.FiltersOperator.or}
      </button>
    </div>
  );
}

export default FiltersOperator;
