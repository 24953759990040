export default {
  presign_url: 'http://app.test/ajax/media/get-upload-parameters',
  s3_image_exchange_url: 'http://app.test/ajax/media/s3/exchange',
  upload_url: 'http://app.test/filepond/api/process',
  save_media_url: 'http://app.test/ajax/media',
  save_images_url: 'http://app.test/ajax/collabs/1234/images',
  images: [
    {
      image_media_id: '1234',
      sort_order: 0,
      caption: 'Example Caption',
      preview: null,
      sizes: {
        thumbnail: {
          url: 'http://zsl.io/h5L0jW+',
          width: 150,
          height: 150
        },
        original: {
          url: 'http://zsl.io/i1gqpI+',
          width: 700,
          height: 795
        }
      }
    }
  ],
  max_width: 5120,
  max_height: 5120,
};
