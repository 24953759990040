import _ from 'lodash';

export const makeImage = (serverImage) => ({
  image: serverImage,
  src: _.get(serverImage, 'sizes.thumbnail.url', null),
  width: _.get(serverImage, 'sizes.thumbnail.width', null),
  height: _.get(serverImage, 'sizes.thumbnail.height', null),
});

const fakeImages = [
  { url: 'http://zsl.io/cZH9G3+', width: 658, height: 746 },
  { url: 'http://zsl.io/z2SeSU+', width: 1184, height: 671 },
  { url: 'http://zsl.io/MlpQnx+', width: 697, height: 697 },
  { url: 'http://zsl.io/MVDa3F+', width: 560, height: 697 },
  { url: 'http://zsl.io/3SOLGc+', width: 612, height: 612 },
];

let lastFakeImageId = -1;

export const makeFakeImage = () => {
  const imageIndex = ++lastFakeImageId;
  return {
    image_media_id: Math.random(),
    sort_order: 0,
    caption: 'Example Caption',
    preview: null,
    sizes: {
      small: {
        url: fakeImages[imageIndex].url,
        width: fakeImages[imageIndex].width,
        height: fakeImages[imageIndex].height
      }
    }
  };
};
