import React, { useState, useEffect } from 'react';
import Modal from '../modal/Modal';
import _ from 'lodash';
import { strings } from '../../localization/localization';

function BulkSetInventoryModal({ isOpen, onCancel, onConfirm, ...rest }) {
  const [inventory, setInventory] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setInventory('');
    }
  }, [isOpen]);

  const _setInventory = (inventory) => {
    if (inventory.trim() === '') {
       setInventory('');
    } else {
      const casted = inventory === '0' ? 0 : parseInt(inventory);

      if (!_.isFinite(casted)) return;

      setInventory(casted);
    }
  };

  return (
    <Modal
      title={strings.BulkSetInventoryModal.bulkSetInventoryLevels}
      isOpen={isOpen}
      buttons={[
        {
          style: 'white',
          text: strings.BulkSetInventoryModal.cancel,
          onClick: () => {
            onCancel();
          }
        },
        {
          style: 'black',
          text: strings.BulkSetInventoryModal.set,
          onClick: () => {
            if (inventory === '') {
               onConfirm('');
            } else {
              onConfirm(parseInt(inventory));
            }
          },
        }
      ]}
      onRequestClose={() => {
        onCancel();
      }}
      autoFocus={false}
      {...rest}
    >
      <h3>{strings.BulkSetInventoryModal.inventoryLevels}</h3>
      <input
        type="text"
        value={inventory}
        onChange={e => _setInventory(e.target.value)}
        autoFocus={true}
      />
    </Modal>
  );
}

BulkSetInventoryModal.defaultProps = {
  isOpen: false,
  onCancel: () => {},
  onConfirm: () => {},
};

export default BulkSetInventoryModal;
