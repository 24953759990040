import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import arrowIcon from '../../images/back.svg';
import { strings } from '../../localization/localization';

const restrictionsLabels = {
  min_width: strings.Instructions.minWidth /*[OG: 'Min width']*/,
  min_height: strings.Instructions.minHeight /*[OG: 'Min height']*/,
  max_width: strings.Instructions.maxWidth /*[OG: 'Max width']*/,
  max_height: strings.Instructions.maxHeight /*[OG: 'Max height']*/,
  max_images: strings.Instructions.maxImages /*[OG: 'Max Images']*/,
};

const getRestrictions = (jsonData, defaultJsonData) => {
  const defaults = defaultJsonData;
  const restrictonsKeys = ['min_width', 'min_height', 'max_width', 'max_height', 'max_images'];

  const restrictions = _.pickBy(jsonData, (val, key) => {
    return restrictonsKeys.indexOf(key) !== -1 && val !== defaults[key];
  });

  return _.map(restrictions, (val, key) => (
    `${restrictionsLabels[key]}: ${val}`
  ))
  .join(', ');
};

function Instructions(props) {
  const { jsonData, defaultJsonData } = props;
  const [fullShowing, setFullShowing] = useState(false);
  const [instructions, setInstructions] = useState(null);
  const [restrictions, setRestrictions] = useState(null);

  useEffect(() => {
    setInstructions(jsonData.instructions);
    setRestrictions(getRestrictions(jsonData, defaultJsonData));
  }, [jsonData, defaultJsonData]);

  if (!jsonData || (!instructions && !restrictions)) return null;

  const toggle = () => {
    return (
      <button
        className={`instructions-toggle ${fullShowing ? 'expanded':''}`}
        onClick={() => setFullShowing(!fullShowing)}
      >
        <img src={arrowIcon} alt={fullShowing ? strings.Instructions.collapse /*[OG: 'Collapse']*/ : strings.Instructions.expand /*[OG: 'Expand']*/} />
      </button>
    );
  };

  if (!fullShowing) {
    return (
      <div className="instructions">
        <p
          className="raleway-regular raleway-14"
          dangerouslySetInnerHTML={{ __html: instructions }}
        />
        {toggle()}
      </div>
    );
  }

  return (
    <div className="instructions">
      {!!instructions && (
        <>
          <h3 className="raleway-semi-bold raleway-12">
            {strings.Instructions.imageInstructions /*[OG: 'Image Instructions']*/}
          </h3>
          <p
            className="raleway-regular raleway-14"
            dangerouslySetInnerHTML={{ __html: instructions }}
          />
        </>
      )}

      {!!instructions && !!restrictions && (
        <div className="separator" />
      )}

      {!!restrictions && (
        <>
          <h3 className="raleway-semi-bold raleway-12">
            {strings.Instructions.imageRestrictions /*[OG: 'Image Restrictions']*/}
          </h3>
          <p
            className="raleway-regular raleway-14"
            dangerouslySetInnerHTML={{ __html: restrictions }}
          />
        </>
      )}
      {toggle()}
    </div>
  );
}

export default Instructions;
