import React from 'react';

function UIBlocker({ block }) {
  if (!block) return null;
  
  return (
    <div className="ui-blocker" />
  );
}

export default UIBlocker;
