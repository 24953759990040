import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {isDev, getJSONData} from '../../utils';
import axios from 'axios';
import $ from 'jquery';
import Select, {components} from 'react-select';
import UIBlocker from '../UIBlocker';
import {strings} from '../../localization/localization';

const bulkActionsMount = document.getElementById('bulk_actions_mount');

const fakeJSONData = {
    context: 'social_profiles'
};

const defaultJsonData = {
    context: null,
    actions: []
};

const checkboxSelector = 'input[type=checkbox]';
const getCheckedIds = () => (
    $('.data-table').find(checkboxSelector + '[name="bulk_actions_select[]"]:checked')
        .toArray()
        .map(cb => cb.value)
);

const attachBulkCheckboxHandlers = () => {
    let lastChecked = null;
    const $dataTable = $('.data-table');
    const checkboxSelector = 'input[name="bulk_actions_select[]"]';
    const allCheckboxSelector = 'input[name="bulk_actions_select_all"]';

    const handleClick = (e) => {
        const checkbox = $(e.target);
        const all = checkbox.attr('name') === 'bulk_actions_select_all';

        $('#data_table_select_all_warning').remove();

        if (all) {
            console.log('BulkActions - click - all', {
                event: e,
                lastChecked,
            });

            $dataTable.find(checkboxSelector).prop('checked', e.target.checked);
            window.setBulkActionIds(getCheckedIds());
            
            if (getCheckedIds().length > 0) {
                $('.dataTables_wrapper').prepend('<div id="data_table_select_all_warning" class="raleway-semi-bold raleway-12">WARNING: Clicking <em>Select All</em> only selects all records on the <em>Current Page</em>. To run a <em>Bulk Action</em> on all records in the query, configure your <em>Filters</em> and <em>Search</em>, then run the bulk action without selecting any records.</div>');   
            }
            
            return;
        }

        if (!lastChecked) {
            console.log('BulkActions - click - !lastChecked', {
                event: e,
                lastChecked,
            });

            lastChecked = checkbox;
            window.setBulkActionIds(getCheckedIds());
            return;
        }

        if (e.shiftKey && lastChecked) {
            var from = $dataTable.find(checkboxSelector).index(checkbox);
            var to = $dataTable.find(checkboxSelector).index(lastChecked);

            var start = Math.min(from, to);
            var end = Math.max(from, to) + 1;

            $dataTable.find(checkboxSelector).slice(start, end)
                .prop('checked', lastChecked.prop('checked'));

            console.log('BulkActions - click - e.shiftKey && lastChecked', {
                e,
                lastChecked,
                from,
                to,
                start,
                end,
                '$dataTable.find(checkboxSelector).slice(start, end)': $dataTable.find(checkboxSelector).slice(start, end),
                'lastChecked.checked': lastChecked.checked,
            });
        }

        lastChecked = checkbox;

        window.setBulkActionIds(getCheckedIds());

        console.log('BulkActions - click - End', {
            e,
            lastChecked,
        });
    };

    $dataTable.on('click', checkboxSelector, handleClick);
    $(allCheckboxSelector).on('click', handleClick);
};

function BulkActions(props) {
    const [ready, setReady] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [context, setContext] = useState('');
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(null);
    const [ids, setIds] = useState([]);

    useEffect(() => {
        if (!!bulkActionsMount) {
            initialize();
        }
    }, []);

    useEffect(() => {
        console.log('BulkActions - ids', ids);
    }, [ids]);

    const initialize = () => {
        const jsonData = {...defaultJsonData, ...getJSONData('#bulk_actions_mount', isDev() ? fakeJSONData : null)};

        setContext(jsonData.context);

        window.bulkActions = {selectedIds: []};
        window.getBulkActionIds = () => window.bulkActions.selectedIds;
        window.setBulkActionIds = ids => {
            setIds([...ids]);
            window.bulkActions.selectedIds = [...ids];

            console.log('BulkActions - setBulkActionIds', ids);
        };

        const supportedActions = jsonData.bulk_actions || [];
        const formData = jsonData.form_data || {};
        
        // If there's a new_tab search param in the url, open that tab and remove the search param
        const searchParams = new URLSearchParams(document.location.search);
        const newTabUrl = searchParams.get('new_tab');
        
        if (newTabUrl) {
            searchParams.delete('new_tab');
            
            const newQuery = searchParams.toString();

            window.history.replaceState("", "", newQuery ? '?' + newQuery : window.location.href.split("?")[0]);
            
            window.open(newTabUrl, '_blank');
        }

        // Load server data
        axios.get(jsonData.ajax_url, {
            params: {
                bulk_actions: supportedActions,
                form_data: formData,
            },
            crossDomain: true
        })
            .then(function (response) {
                // handle success
                setActions(response.data.available_actions);
                setReady(true);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });

        attachBulkCheckboxHandlers();
    }

    const confirmSelection = (selectedAction, ids) => {
        if (ids.length) {
            return window.confirm(strings.formatString(strings.BulkActions.confirmApplyBulkAction, {bulkAction: selectedAction._bulkAction.name}));
        } else {
            return window.confirm(strings.formatString(strings.BulkActions.confirmNoRecordsSelected, {bulkAction: selectedAction._bulkAction.name}));
        }
    }

    const apply = (selectedAction, ids, e) => {
        e.preventDefault();
        if (!selectedAction) return;
        if (selectedAction.disabled) {
            setSelectedAction(null);
            return;
        }

        if (!confirmSelection(selectedAction, ids)) return;

        if (isDev()) {
            console.log('Submitted');
        } else {
            setSubmitting(true);
            $('#bulk_actions').submit();
        }
    }

    if (!ready) return null;

    const component = (
        <div className="ui-block-container">
            <Select
                isMulti={false}
                options={actions.map(a => ({
                    label: a.name,
                    value: a.slug,
                    _bulkAction: a,
                }))}
                onChange={selectedOption => {
                    setSelectedAction(selectedOption);
                }}
                name="action"
                value={selectedAction}
                className='raleway-semi-bold raleway-12 react-select-container'
                components={{
                    Option: ({innerProps, data, isDisabled, ...restProps}) => {
                        const customInnerProps = {...innerProps};
                        if (data._bulkAction.disabled && data._bulkAction.disabled_reason) {
                            const disabledMsg = `${strings.BulkActions.disabled}: ${data._bulkAction.disabled_reason}`;
                            customInnerProps.onClick = () => {
                                alert(disabledMsg);
                            };
                            customInnerProps.title = disabledMsg;
                        }

                        return (
                            <components.Option
                                innerProps={customInnerProps}
                                data={data}
                                isDisabled={data._bulkAction.disabled} {...restProps}
                            />
                        );
                    }
                }}
            />
            <button
                onClick={e => apply(selectedAction, ids, e)}
                className="btn"
            >
                {strings.BulkActions.apply}
            </button>
            <input key="context" type="hidden" name="context" value={context}/>
            {ids.map((id, i) => (
                <input key={id} type="hidden" name={`ids[${i}]`} value={id}/>
            ))}
            <UIBlocker block={submitting}/>
        </div>
    );

    if (!bulkActionsMount) {
        return null;
    }

    return ReactDOM.createPortal(
        component,
        bulkActionsMount,
    );
}

export default BulkActions;
