import _ from 'lodash';

export const initialState = {
  ready: false,
  images: [],
  imagesChangeTimestamp : null,
  saving: false,
  currentImage: 0,
  viewerIsOpen: false,
  files: [],

  // Compact Mode
  singleFileProgress: null
};

const sortImages = state => {
  const newState = { ...state };

  if (newState.images) {
    newState.images = _.sortBy(state.images, ['sort_order']);
  }

  return newState;
};

export const excludeInvalidImages = (images) => {
  return images.filter(image => {
    return !!image.src;
  });
};

const triggerImagesSave = (state) => ({
  ...state,
  imagesChangeTimestamp: (new Date()).getTime()
});

const selected = (state) => state.images.filter(image => image.image.selected);
const notSelected = (state) => state.images.filter(image => !image.image.selected);
const setSelected = (state, selected = true) => {
  return {
    ...state,
    images: state.images.map(image => ({
      ...image,
      image: {
        ...image.image,
        selected
      }
    }))
  };
};

let newState = null;

export const reducer = (state, { type, payload = null }) => {
  switch(type) {
    case 'HYDRATE':
      newState = {
        ...state,
        ...payload,
      };

      newState = {
        ...newState,
        images: excludeInvalidImages(newState.images),
      };

      return sortImages(newState);

    case 'REORDER_IMAGES':
      newState = {
        ...state,
        images: payload.images
      };

      return triggerImagesSave(newState);

    case 'ADD_IMAGES':
      const newImages = payload.images;
      const images = [...state.images, ...excludeInvalidImages(newImages)];

      newState = sortImages({
        ...state,
        images,
      });

      return triggerImagesSave(newState);

    case 'REMOVE_IMAGES':
      const { imageMediaIds } = payload;

      newState = {
        ...state,
        images: state.images.filter(image => {
          return imageMediaIds.indexOf(image.image.image_media_id) === -1;
        })
      };

      return triggerImagesSave(newState);

    case 'REMOVE_SELECTED':
      newState = {
        ...state,
        images: notSelected(state)
      };

      return triggerImagesSave(newState);

    case 'TOGGLE_SELECTED':
      const { imageMediaId } = payload;
      return {
        ...state,
        images: state.images.map(image => {
          if (image.image.image_media_id === imageMediaId) {
            image.image.selected = !image.image.selected;
          }

          return image;
        })
      };

    case 'TOGGLE_ALL_SELECTED':
      const allSelected = selected(state).length === state.images.length;

      return setSelected(state, !allSelected);

    case 'SET_FILES':
      return {
        ...state,
        files: payload
      };

    case 'SET_SINGLE_FILE_PROGRESS':
      return {
        ...state,
        singleFileProgress: payload
      };

    default:
      throw new Error();
  }
}
