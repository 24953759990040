import React from 'react';
import { operatorsMap, optionsToSelectOptions, selectedToOptionValue, filterValueToSelectValue } from './utils';
import Select from 'react-select';
import trashCanIcon from '../../images/trash-can.png';
import { strings } from '../../localization/localization';

function FilterRow({ state, dispatch, filter }) {
  const changeFilterOperator = (client_id, operator) => {
    dispatch({
      type: 'UPDATE_FILTER',
      payload: {
        client_id,
        values: {
          operator
        }
      }
    });
  }

  const changeFilterValue = (client_id, value, field_type) => {
    const sanitized = field_type === 'number' ?
      value.replace(/\D/g,'') :
      value;

    dispatch({
      type: 'UPDATE_FILTER',
      payload: {
        client_id,
        values: {
          value: sanitized
        }
      }
    });
  }

  const removeFilter = (client_id) => {
    dispatch({
      type: 'REMOVE_FILTER',
      payload: {
        client_id
      }
    })
  }

  const operators = filter.operators.map(o => ({ label: operatorsMap[o].name, value: o }));
  const selectedOperator = { label: operatorsMap[filter.operator].name, value: filter.operator };

  const options = optionsToSelectOptions(filter.optionsData);
  const selectedOptions = filterValueToSelectValue(filter.optionsData, filter.value, filter.field_type);

  return (
    <tr
      className="filter-row"
    >
      <td>
        <div className="filter-type">
          {strings.FilterRow.type}: {filter.type}
        </div>
      </td>
      <td>
        <div className="raleway-semi-bold raleway-12 filter-name">
          {filter.name}
        </div>
      </td>
      <td>
        <div className="filter-operator">
          <Select
            isMulti={false}
            options={operators}
            onChange={selectedOption => {
              changeFilterOperator(filter.client_id, selectedOption.value);
            }}
            value={selectedOperator}
            className='raleway-semi-bold raleway-12 react-select-container'
          />
        </div>
      </td>
      <td>
        <div className="filter-value">
          {['text','number'].includes(filter.field_type) && (
            <input
              type="text"
              onChange={e => changeFilterValue(filter.client_id, e.target.value, filter.field_type)}
              value={filter.value || ''}
              className='text-input'
            />
          )}

          {['select', 'select_multiple'].indexOf(filter.field_type) !== -1 && (
            <>
              {filter.optionsDataLoaded && (
                <Select
                  isMulti={filter.field_type === 'select_multiple'}
                  options={options}
                  onChange={selectedOptions => {
                    changeFilterValue(filter.client_id, selectedToOptionValue(selectedOptions), filter.field_type);
                  }}
                  value={selectedOptions}
                  className='raleway-semi-bold raleway-12 react-select-container'
                />
              )}
              {!filter.optionsDataLoaded && (
                <span className="raleway-semi-bold raleway-12 options-loading">{strings.FilterRow.loadingOptions}</span>
              )}
            </>
          )}
        </div>
      </td>
      <td>
        <div className="filter-remove">
          <button onClick={e => removeFilter(filter.client_id)}>
            <img src={trashCanIcon} alt="" />
          </button>
        </div>
      </td>
    </tr>
  );
}

export default FilterRow;
