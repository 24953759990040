import React, { useState, useRef } from 'react';
import Image from './Image';
import { SortableElement } from "react-sortable-hoc";

const SortableImage = SortableElement(item => <Image {...item} />);

const preferredImageWidth = 75;
const maxImageWidth = 100;

function Gallery(props) {
  const { images, onClick, compact } = props;
  const wrapEl = useRef(null);

  const calculateColumns = () => {
    if (!wrapEl.current || wrapEl.current.clientWidth === 0) return 4;

    return Math.floor(wrapEl.current.clientWidth / preferredImageWidth);
  };

  const calculateImageWidth = () => {
    if (!wrapEl.current || wrapEl.current.clientWidth === 0) return 25;


    return Math.min((1 / calculateColumns() * 100), maxImageWidth);
  };

  const imageWidth = calculateImageWidth();

  return (
    <div
      className={`gallery columns-${calculateColumns()}`}
      ref={wrapEl}
    >
      {images.map((image, index) => (
        <SortableImage
          width={imageWidth}
          index={index}
          _index={index}
          image={image}
          onClick={onClick}
          key={image.image.image_media_id}
          disabled={compact}
          compact={compact}
        />
      ))}
    </div>
  );
}

export default Gallery;
