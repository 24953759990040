import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { isDev } from './utils';
import SegmentationFilter from './components/segmentation-filter/SegmentationFilter';
import BulkActions from './components/bulk-actions/BulkActions';
import Imports from './components/imports/Imports';
import Exports from './components/exports/Exports';
import JobsIndicator from './components/jobs-indicator/JobsIndicator';
import ImagesUploader from './components/images-uploader/ImagesUploader';
import ProductVariants from './components/product-variants/ProductVariants';
import { strings } from './localization/localization';

const defaultDevTab = 'images-uploader';

const devTabs = [
  { name: strings.App.segmenationFilter,  className: 'segmentation-filter' },
  { name: strings.App.bulkActions,        className: 'bulk-actions'        },
  { name: strings.App.imports,            className: 'imports'             },
  { name: strings.App.exports,            className: 'exports'             },
  { name: strings.App.jobsIndicator,      className: 'jobs-indicator'      },
  { name: strings.App.imagesUploader,     className: 'images-uploader'     },
  { name: strings.App.productVariants,    className: 'product-variants'    },
];

const renderImagesUploaders = () => {
  $('.images-uploader:empty').each(function() {
    ReactDOM.render(<ImagesUploader mountPoint={this} />, this);
  });
};

function App(props) {
  const [activeDevTab, setActiveDevTab] = useState(null);
  const [lastImportId, setLastImportId] = useState(null);
  const [lastExportId, setLastExportId] = useState(null);

  useEffect(() => {
    window.renderImagesUploaders = renderImagesUploaders;

    renderImagesUploaders();

    setActiveDevTab(defaultDevTab);
  }, []);

  useEffect(() => {
    $(`.dev-ui`).removeClass('active');
    $(`.dev-ui.${activeDevTab}`).addClass('active');
  }, [activeDevTab]);

  return (
    <>
      {isDev() && (
        <ul className="raleway-semi-bold raleway-12 dev-tabs">
          {devTabs.map(({ name, className }) => (
            <li
              className={`${className} ${activeDevTab === className ? 'active':''}`}
              onClick={() => setActiveDevTab(className)}
              key={className}
            >
              {name}
            </li>
          ))}
        </ul>
      )}
      <SegmentationFilter />
      <BulkActions />
      <Imports onCreate={lastImportId => {
        setLastImportId(lastImportId);
        console.log('App lastImportId', lastImportId)
      }} />
      <Exports onCreate={lastExportId => {
        setLastExportId(lastExportId);
        console.log('App lastExportId', lastExportId)
      }} />
      <JobsIndicator lastImportId={lastImportId} lastExportId={lastExportId} />
      <ProductVariants />
    </>
  );
}

export default App;
