import React from 'react';
import _ from 'lodash';
import eyeIcon from '../../images/eye2.svg';
import eyeIconHover from '../../images/eye2-white.svg';
import xIcon from '../../images/apex-x-black.svg';
import xIconHover from '../../images/apex-x.svg';
import checkUnselected from '../../images/check-unselected.svg';
import checkSelected from '../../images/check-selected.svg';
import { strings } from '../../localization/localization';

function Image(props) {
  const { _index, onClick, image: { image }, width, compact } = props;
  const { image_media_id } = image;

  const handleClick = event => {
    event.stopPropagation();
    event.preventDefault();

    if (!image_media_id) return;

    const isDelete = event.currentTarget.className === 'delete';
    const isView = event.currentTarget.className === 'view';

    // Prevent selection in compact mode
    if (compact && !isDelete && !isView) return;

    onClick({ image, isDelete, isView }, { index: _index });
  };

  return (
    <div
      className={`images-uploader-image noselect ${image.selected ? 'selected' : ''} ${compact ? 'compact' : ''}`}
      onClick={onClick ? handleClick : null}
      style={compact ? null : { width: `${width}%`}}
    >
      {!!image_media_id && (
        <>
          <img
            src={_.get(image, 'sizes.thumbnail.url')}
            width={_.get(image, 'sizes.thumbnail.width')}
            height={_.get(image, 'sizes.thumbnail.height')}
            alt={_.get(image, 'caption')}
          />
          <div className="selected-check">
            <img src={image.selected ? checkSelected : checkUnselected} alt={strings.Image.selected /*[OG: 'Selected']*/} />
          </div>
          <button
            className="view"
            onClick={handleClick}
          >
            <img src={eyeIcon} alt={strings.Image.view /*[OG: 'View']*/} />
            <img className="hover" src={eyeIconHover} alt={strings.Image.view /*[OG: 'View']*/} />
          </button>
          <button
            className="delete"
            onClick={handleClick}
          >
            <img src={xIcon} alt={strings.Image.delete /*[OG: 'Delete']*/} />
            <img className="hover" src={xIconHover} alt={strings.Image.delete /*[OG: 'Delete']*/} />
          </button>
        </>
      )}
      {!image_media_id && (
        <div className="image-loading">
          ...
        </div>
      )}
    </div>
  );
}

export default Image;
