import React, { useCallback } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import trashCanIcon from '../../images/trash.svg';
import { actions as a } from './reducer';
import ImagesUploader from '../images-uploader/ImagesUploader';
import { strings } from '../../localization/localization';

const createSelectOption = (optionValue) => {
  if (!optionValue) return null;

  return {
    label: optionValue.name,
    value: optionValue.clientId,
  }
};

function VariantRow(props) {
  const {
    options,
    variant: {
      clientId,
      options: variantOptions,
      image,
      inventory,
      price,
      sku,
      isDuplicate,
      isDuplicateSku,
      isIncomplete
    },
    imagesUploaderConfig
  } = props;

  const handleOptionChange = useCallback((optionClientId, selectedValue) => {
    const selectedValueClientId = selectedValue.value;
    a.setVariantOption(clientId, optionClientId, selectedValueClientId);
  }, [clientId]);

  const onRemove = useCallback(e => {
    e.preventDefault();

    if (!window.confirm(strings.VariantRow.areYouSure)) return;

    a.removeVariant(clientId);
  }, [clientId]);

  return (
    <tr className={`product-variant-row ${isDuplicate ? 'duplicate':''} ${isDuplicateSku ? 'duplicate-sku':''} ${isIncomplete ? 'incomplete':''}`}>

      <td className="image">
        <ImagesUploader
          compact
          images={!!image ? [image] : []}
          onImageAdded={image => a.setVariantImage(clientId, image)}
          onImageDeleted={image => a.setVariantImage(clientId)}
          config={imagesUploaderConfig}
        />
      </td>

      {options.map((option, optionIndex) => (
        <td className={`option ${option.name.toLowerCase()}`} key={option.name}>
          <Select
            className="raleway-semi-bold raleway-12 react-select-container"
            options={option.values.map(ov => createSelectOption(ov))}
            onChange={selectedValueClientId => handleOptionChange(option.clientId, selectedValueClientId)}
            value={createSelectOption(
              _.find(option.values, { clientId: variantOptions[option.clientId] })
            )}
          />
        </td>
      ))}

      <td className="inventory">
        <input
          type="text"
          name=""
          value={_.toString(inventory)}
          onChange={e => a.setVariantInventory(clientId, e.target.value)}
          className="text-input"
          placeholder={strings.VariantRow.inventoryPlaceholder}
        />
      </td>

      <td className="price">
        <input
          type="text"
          name=""
          value={_.toString(price)}
          onChange={e => a.setVariantPrice(clientId, e.target.value)}
          className="text-input"
          placeholder={strings.VariantRow.pricePlaceholder}
        />
      </td>

      <td className="sku">
        <input
          type="text"
          name=""
          value={_.toString(sku)}
          onChange={e => a.setVariantSku(clientId, e.target.value)}
          className="text-input"
          placeholder={strings.VariantRow.skuPlaceholder}
        />
      </td>

      <td className="delete">
        <button
          className="btn btn-icon delete-variant"
          onClick={onRemove}
        >
          <img src={trashCanIcon} alt={strings.VariantRow.delete} />
        </button>
      </td>

    </tr>
  );
}

export default VariantRow;
