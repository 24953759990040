const screens = {
  App: {
    segmenationFilter: 'Segmenation Filter',
    bulkActions: 'BulkActions',
    imports: 'Imports',
    exports: 'Exports',
    jobsIndicator: 'Jobs Indicator',
    imagesUploader: 'Images Uploader',
    productVariants: 'Product Variants',
  },
};

const components = {
  // bulk-actions
  BulkActions: {
    confirmApplyBulkAction: 'Are you sure you want to apply the {bulkAction} action to all of the selected records?',
    confirmNoRecordsSelected: 'You do not have any records selected. Are you sure you want to apply the {bulkAction} action to the entire current segment?',
    disabled: 'Disabled',
    apply: 'Apply',
  },
  // exports
  Exports: {
    sensitiveWarning: 'WARNING: You are exporting columns with sensitive information in them. Are you sure you want to continue?',
    runConfirmation: 'Are you sure you want to run this export?',
    errorCreatingExport: 'There was an error creating your export.',
    sensitive: '* Sensitive',
    exports: 'Exports',
    export: 'Export',
    cancel: 'Cancel',
    chooseExportType: 'Choose an Export Type',
    name: 'Name',
    optional: '(optional)',
    ie: 'ie.',
    columns: 'Columns',
    options: 'Options',
    downloadExampleFile: 'Download Example File',
  },
  // images-uploader
  CompactFilesList: {
    failed: 'Failed',
  },
  ImagesUploader: {
    confirmDeleteImages: 'Are you sure you want to delete these images?',
    dragDropFiles: 'Drag & Drop your files or',
    browse: 'Browse',
    delete: 'Delete',
    deselectAll: 'Deselect All',
    selectAll: 'Select All',
    errorUploadingMediaFile: 'Error uploading media file',
  },
  Instructions: {
    minWidth: 'Min width',
    minHeight: 'Min height',
    maxWidth: 'Max width',
    maxHeight: 'Max height',
    maxImages: 'Max Images',
    collapse: 'Collapse',
    expand: 'Expand',
    imageInstructions: 'Image Instructions',
    imageRestrictions: 'Image Restrictions',
  },
  Image: {
    selected: 'Selected',
    view: 'View',
    delete: 'Delete',
  },
  // imports
  Imports: {
    confirmRunImport: 'Are you sure you want to run this import?',
    errorCreatingImport: 'There was an error creating your import.',
    imports: 'Imports',
    import: 'Import',
    cancel: 'Cancel',
    chooseImportType: 'Choose an Import Type',
    name: 'Name',
    optional: '(optional)',
    ie: 'ie.',
    errorUploadingImportFile: 'Error uploading import file',
    options: 'Options',
    help: 'Help',
    downloadExampleFile: 'Download Example File',
  },
  // jobs-indicator
  Job: {
    cancelled: 'Cancelled',
    complete: 'Complete',
    failed: 'Failed',
    statusUnknown: 'Status Unknown',
    download: 'Download',
    errors: 'Errors',
    last: 'Last',
    serverError: 'Server Error',
  },
  JobsIndicator: {
    importFinishedRefresh: 'Your import is finished. Do you want to refresh the page to see the results?',
    refresh: 'Refresh',
    imports: 'Imports',
    exports: 'Exports',
    noRecentImportsExports: 'There are no recent imports/exports',
  },
  // product-variants
  BulkSetInventoryModal: {
    bulkSetInventoryLevels: 'Bulk Set Inventory Levels',
    cancel: 'Cancel',
    set: 'Set',
    inventoryLevels: 'Inventory Level',
  },
  BulkSetPriceModal: {
    bulkSetPrice: 'Bulk Set Price',
    cancel: 'Cancel',
    set: 'Set',
    price: 'Price',
  },
  OptionRow: {
    variantDeletionDisclaimer: 'Variants use this option value. If you delete this option, those Variants will be removed. Are you sure?',
    optionDeletionDisclaimer: 'This option will be removed from all Variants. Are you sure?',
    optionNamePlaceholder: 'Type an option name...',
    optionValuesPlaceholder: 'Type something and press enter...',
    delete: 'Delete',
  },
  ProductVariants: {
    productVariants: 'Product Variants',
    productOptions: 'Product Options',
    addOption: 'Add Option',
    img: 'Img',
    inventory: 'Inventory',
    price: 'Price',
    sku: 'SKU',
    addVariant: 'Add Variant',
  },
  RenameOptionValueModal: {
    bulkSetInventoryLevels: 'Bulk Set Inventory Levels',
    cancel: 'Cancel',
    set: 'Set',
    rename: 'Rename',
  },
  VariantRow: {
    areYouSure: 'Are you sure?',
    inventoryPlaceholder: 'ie. 100',
    pricePlaceholder: 'ie. 10.00',
    skuPlaceholder: 'ie. ABC-123',
    delete: 'Delete',
  },
  VariantsSelectBar: {
    confirmDeleteVariants: 'Are you sure you want to delete the {length} selected variants?',
    select: 'Select',
    setInventory: 'Set Inventory',
    setPrice: 'Set Price',
    delete: 'Delete',
    clear: 'Clear',
    by: 'By',
  },
  // segmentation-filter
  AddFilter: {
    addNewFilter: 'Add New Filter',
  },
  FilterRow: {
    type: 'Type',
    loadingOptions: 'Loading Options...',
  },
  FiltersOperator: {
    and: 'AND',
    or: 'OR',
  },
  LoadSegmentation: {
    confirmLoadUnsaved: 'You have unsaved filters. Are you sure you want to load an existing segmentation?',
    loadSegment: 'Load Segment',
  },
  SegmentationFilter: {
    nameRequired: 'Segmentation Name is required.',
    errorSavingSegmentation:'Error saving Named Segmentation',
    provideUniqueName: 'Please provide a unique Segmentation Name',
    confirmDeleteSegmentation: 'Are you sure you want to delete the named segmentation',
    confirmCancel: 'You have unsaved filters. Are you sure you want to cancel?',
    confirmClear: 'You have unsaved filters. Are you sure you want to clear your fliters?',
    newSegment: 'New Segment',
    segmentName: 'Segment Name',
    delete: 'Delete',
    addFilter: 'Add Filter',
    clear: 'Clear',
    loadSegment: 'Load Segment',
    save: 'Save',
    saveAsNew: 'Save as New',
    addSomeFilters: 'Add Some Filters!',
    unsavedFilters: '* You have unsaved filters',
    cancel: 'Cancel',
    apply: 'Apply',
  },
  Utils: {
    equalTo: 'Equal To',
    notEqualTo: 'Not Equal To',
    contains: 'Contains',
    doesNotContain: 'Does Not Contain',
    oneOf: 'One Of',
    notOneOf: 'Not One Of',
    greaterThan: 'Greater Than',
    lessThan: 'Less Than',
    greaterThanOrEqualTo: 'Greater Than or Equal To',
    lessThanOrEqualTo: 'Less Than or Equal To',
    hasAnyOf: 'Has Any Of',
    hasAllOf: 'Has All Of',
  },
};

export default ({
  ...screens,
  ...components,
});
