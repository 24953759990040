export default {
  value: {
    options: [
      {
        server_id: '1',
        name: 'Color',
        values: [
          {
            server_id: '1',
            name: 'Red'
          },
          {
            server_id: '2',
            name: 'Blue'
          }
        ]
      },
      {
        server_id: '2',
        name: 'Size',
        values: [
          {
            server_id: '3',
            name: 'Small'
          },
          {
            server_id: '4',
            name: 'Medium'
          }
        ]
      }
    ],
    variants: [
      {
        server_id: '1',
        image: {
          image_media_id: '1234',
          sort_order: 0,
          caption: 'Example Caption',
          preview: null,
          sizes: {
            thumbnail: {
              url: 'http://zsl.io/h5L0jW+',
              width: 150,
              height: 150
            },
            original: {
              url: 'http://zsl.io/i1gqpI+',
              width: 700,
              height: 795
            }
          }
        },
        options: [
          {
            server_id: '2',
            option_server_id: '1'
          },
          {
            server_id: '4',
            option_server_id: '2'
          }
        ],
        inventory: null,
        price: 1000,
        sku: 'THING-1234'
      },
      {
        server_id: '2',
        image: null,
        options: [
          {
            server_id: '1',
            option_server_id: '1'
          },
          {
            server_id: '4',
            option_server_id: '2'
          }
        ],
        inventory: 10,
        price: 1000,
        sku: 'THING-1235'
      },
      {
        server_id: '3',
        image: null,
        options: [
          {
            server_id: '1',
            option_server_id: '1'
          },
          {
            server_id: '4',
            option_server_id: '2'
          }
        ],
        inventory: null,
        price: 1000,
        sku: 'THING-1236'
      },
      {
        server_id: '4',
        image: null,
        options: [
          {
            server_id: '2',
            option_server_id: '1'
          }
        ],
        inventory: null,
        price: 1000,
        sku: 'THING-1237'
      }
    ]
  }
};
