import React from 'react';
import _ from 'lodash';
import { strings } from '../../localization/localization';

function VariantsInputs({ state }) {
  return (
    <div className="product-variants-inputs">

      {state.variants.map((v, i) =>
        <div key={`product_variants[${i}]`}>
          <input
            type="hidden"
            name={`product_variants[${i}][client_id]`}
            value={v.clientId}
            key={`product_variants[${i}][client_id]`}
          />
          <input
            type="hidden"
            name={`product_variants[${i}][server_id]`}
            value={v.serverId || ''}
            key={`product_variants[${i}][server_id]`}
          />
          <input
            type="hidden"
            name={`product_variants[${i}][initial_inventory]`}
            value={v.initialInventory || ''}
            key={`product_variants[${i}][initial_inventory]`}
          />
          <input
            type="hidden"
            name={`product_variants[${i}][inventory]`}
            value={_.toString(v.inventory)}
            key={`product_variants[${i}][inventory]`}
          />
          <input
            type="hidden"
            name={`product_variants[${i}][price]`}
            value={_.toString(v.price)}
            key={`product_variants[${i}][price]`}
          />
          <input
            type="hidden"
            name={`product_variants[${i}][sku]`}
            value={v.sku}
            key={`product_variants[${i}][sku]`}
          />
          {!!v.image && (
            <div key={`product_variants[${i}][images]`}>
              <input
                type="hidden"
                name={`product_variants[${i}][images][0][image_media_id]`}
                value={v.image.image_media_id}
                key={`product_variants[${i}][images][0][image_media_id]`}
              />
              <input
                type="hidden"
                name={`product_variants[${i}][images][0][sort_order]`}
                value={0}
                key={`product_variants[${i}][images][0][sort_order]`}
              />
              <input
                type="hidden"
                name={`product_variants[${i}][images][0][caption]`}
                value={v.image.caption}
                key={`product_variants[${i}][images][0][caption]`}
              />
            </div>
          )}

          <div key={`product_variants[${i}][options]`}>
            {state.options.map((o) => (
                <input
                  type="hidden"
                  name={`product_variants[${i}][options][${o.clientId}]`}
                  value={v.options[o.clientId] || ''}
                  key={`product_variants[${i}][options][${o.clientId}]`}
                />
            ))}
          </div>
        </div>
      )}

    </div>
  );
}

export default VariantsInputs;
