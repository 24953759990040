import React from 'react';

function OptionsInputs({ state }) {
  return (
    <div className="product-options-inputs">

      {state.options.map((o, i) => (
        <div key={`product_options[${i}]`}>
          <input
            type="hidden"
            name={`product_options[${i}][client_id]`}
            value={o.clientId}
            key={`product_options[${i}][client_id]`}
          />
          <input
            type="hidden"
            name={`product_options[${i}][server_id]`}
            value={o.serverId || ''}
            key={`product_options[${i}][server_id]`}
          />
          <input
            type="hidden"
            name={`product_options[${i}][name]`}
            value={o.name || ''}
            key={`product_options[${i}][name]`}
          />
          {o.values.map((ov, ii) => (
            <div key={`product_options[${i}][values][${ii}]`}>
              <input
                type="hidden"
                name={`product_options[${i}][values][${ii}][client_id]`}
                value={ov.clientId}
                key={`product_options[${i}][values][${ii}][client_id]`}
              />
              <input
                type="hidden"
                name={`product_options[${i}][values][${ii}][server_id]`}
                value={ov.serverId || ''}
                key={`product_options[${i}][values][${ii}][server_id]`}
              />
              <input
                type="hidden"
                name={`product_options[${i}][values][${ii}][name]`}
                value={ov.name}
                key={`product_options[${i}][values][${ii}][name]`}
              />
            </div>
          ))}
        </div>
      ))}

    </div>
  );
}

export default OptionsInputs;
