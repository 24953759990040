import $ from 'jquery';

$(document).ready(() => {
  $('.dataTables_wrapper').on('mouseenter', '.notification', e => {
    $(e.target).find('.timestamps').fadeIn(200);
  });

  $('.dataTables_wrapper').on('mouseleave', '.notification', e => {
    $(e.target).find('.timestamps').fadeOut(200);
  });
});
