import { titleCase } from '../utils';
import _ from 'lodash';

const defaultAction = {
  onBeforeProcess: (href, data, $row) => true,
  onProcessing: (data, $row) => {},
  onComplete: (resp, data, $row) => {},
  onError: (error, data, $row) => {},
  blockUI: true,
  allowInterrupt: false
};

/**
 * Collab Review Status
 */
const updateReviewStatus = ($row, newStatus, data) => {
  const $statusButtons = $row.find('.data-table-row-action.review-status');
  $statusButtons.removeClass('active');
  $statusButtons.filter('.'+ newStatus.toLowerCase()).addClass('active');
  $row.find(`.${data.column}`).text(titleCase(newStatus));
}

const createReviewStatusActions = () => {
  const statuses = ['YES', 'NO', 'MAYBE'];
  const actions = {};
  _.each(statuses, status => {
    _.each(['', 'final_'], prefix => {
      actions[`social_profiles.${prefix}review_status_${status.toLowerCase()}`] = {
        ...defaultAction,
        onComplete: (resp, data, $row) => {
          updateReviewStatus($row, status, data);
        },
      };
    });
  });

  return actions;
};

/**
 * Social Profile Approval Status
 */
const updateApprovalStatus = ($row, newStatus, data) => {
  const $statusButtons = $row.find('.data-table-row-action.approval-status');
  $statusButtons.removeClass('active');
  $statusButtons.filter('.'+ newStatus.toLowerCase()).addClass('active');
  $row.find(`.${data.column}`).text(titleCase(newStatus));
}

const createApprovalStatusActions = () => {
  const statuses = ['APPROVED', 'REJECTED'];
  const actions = {};
  _.each(statuses, status => {
    actions[`social_profiles.approval_status_${status.toLowerCase()}`] = {
      ...defaultAction,
      onComplete: (resp, data, $row) => {
        updateApprovalStatus($row, status, data);
      },
    };
  });

  return actions;
};

/**
 * Social Profile Creator Type
 */
const updateCreatorType = ($row, newType, data) => {
  const $typeButtons = $row.find('.data-table-row-action.creator-type');
  $typeButtons.removeClass('active');
  $typeButtons.filter('.'+ newType.toLowerCase()).addClass('active');
  $row.find(`.${data.column}`).text(titleCase(newType));
}

const createCreatorTypeActions = () => {
  const types = ['CREATOR', 'BOTH', 'PHOTOGRAPHER'];
  const actions = {};
  _.each(types, type => {
    actions[`social_profiles.creator_type_${type.toLowerCase()}`] = {
      ...defaultAction,
      onComplete: (resp, data, $row) => {
        updateCreatorType($row, type, data);
      },
    };
  });

  return actions;
};

const actions = {
  example_ajax: {
    onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
    onProcessing: (data, $row) => console.log('Processing ', data.action),
    onComplete: (resp, data, $row) => {
      console.log('Complete ', data.action);
    },
    onError: (error, data, $row) => console.log('Error ', data.action),
    blockUI: true,
    allowInterrupt: true
  },

  'social_profiles.pause_user': {
    onBeforeProcess: (data, $row) => { console.log('Before Process ', data.action); return true; },
    onProcessing: (data, $row) => console.log('Processing ', data.action),
    onComplete: (resp, data, $row) => {
      var $btn = $row.find('.data-table-row-action.pause-user');

      if (resp.paused) {
        alert("User successfully paused for this collab!");
        $btn.addClass('paused').data('paused', true).text('Unpause');
      } else {
        alert("User successfully unpaused for this collab!");
        $btn.removeClass('paused').data('paused', false).text('Pause');
      }
    },
    onError: (error, data, $row) => console.log('Error ', data.action),
    blockUI: true,
    allowInterrupt: true
  },

  ...createReviewStatusActions(),
  ...createApprovalStatusActions(),
  ...createCreatorTypeActions(),

  retry_hose_test_import: {
    ...defaultAction,
    onComplete: (resp, data, $row) => {
      const $typeButtons = $row.find('.data-table-row-action.retry-hose-test-import');
      $typeButtons.hide();
      $row.find(`td > .status`).text('PENDING');
    },
  },
};

export const getActionHandlers = (action) => {
  if (actions[action]) {
    return actions[action];
  }

  return defaultAction;
};

export default actions;
