import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';
import { getActionHandlers } from './actions';
import { isDev } from '../utils';

const makeCancelToken = () => axios.CancelToken.source();

const inFlight = {};
const setInFlight = (action, recordId, cancelToken) => {
  cancelInFlight(action, recordId);

  if (!inFlight[action]) {
    inFlight[action] = [];
  }

  inFlight[action][recordId] = cancelToken;
};
const isInFlight = (action, recordId) => {
  return !!inFlight[action] && !!inFlight[action][recordId];
};
const cancelInFlight = (action, recordId) => {
  if (isInFlight(action, recordId)) {
    inFlight[action][recordId].cancel('Interrupted by user.');
    delete inFlight[action][recordId];
    if (_.keys(inFlight[action]).length === 0) {
      delete inFlight[action];
    }
  }
};
const anyInFlight = () => (!!_.keys(inFlight).length);

const handleAction = async (href, data, $row) => {
  const { record_id, action, ...rest } = data;

  if (data.confirm) {
    if (!window.confirm(data.confirm)) return;
  }

  if (data.double_confirm) {
    if (!window.confirm(data.double_confirm)) return;
  }

  const handlers = getActionHandlers(action);

  if (isInFlight(action, record_id) && !handlers.allowInterrupt) {
    return;
  }

  if (!handlers.onBeforeProcess(href, data, $row)) {
    return;
  }

  const $table = $row.parents('table');
  $table.addClass('processing');
  $table.addClass('processing__'+ data.action +'__'+ record_id);

  $row.addClass('processing');
  $row.addClass('processing__'+ data.action);
  if (handlers.blockUI) {
    $row.addClass('block-ui');
  }

  handlers.onProcessing(data, $row);

  const cancelToken = makeCancelToken();
  setInFlight(action, record_id, cancelToken);

  let success = false;
  const resp = await axios({
    method: 'POST',
    // headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').attr('content') },
    url: href,
    crossDomain: true,
    data: {
      id: record_id,
      action,
      ...rest
    },
    cancelToken: cancelToken.token,
    timeout: isDev() ? 0 : 30000
  })
    .then(response => {
      // handle server-provided error message
      if (response.data.error) {
        alert(response.data.error);
        handlers.onError(new Error(response.data.error), data, $row);
      }
      // handle success
      else if (data.success_message) {
        alert(data.success_message);
        success = true;
      } else {
        success = true;
      }

      return response.data;
    })
    .catch(error => {
      if (axios.isCancel(error)) return;
      // handle error
      if (data.error_message) {
        alert(data.error_message);
      }
      handlers.onError(error, data, $row);
    })
    .finally(() => {
      cancelInFlight(action, record_id);
    });

    // Artifical delay for testing
    // await new Promise((resolve) => { setTimeout(() => resolve(), 3000)});

    handlers.onComplete(resp, data, $row);
    cancelInFlight(action, record_id); // Clear inFlight ref

    if (!anyInFlight()) {
      $table.removeClass('processing');
    }

    $table.removeClass('processing__'+ data.action +'__'+ record_id);

    $row.removeClass('processing');
    $row.removeClass('processing__'+ data.action);
    $row.removeClass('block-ui');

    if (success && data.hide_on_success) {
      $row.hide().addClass('hidden-on-success');
    }

    return resp;
};

$(document).ready(function(){
  const $dataTable = $('.data-table');

  $dataTable.on('click', 'a.data-table-row-action', async function(e) {
    const $a = $(this);
    const $row = $a.parents('tr');
    const data = $a.data();

    if (!data.ajax) return;

    e.preventDefault();

    $a.addClass('processing');

    await handleAction($a.attr('href'), data, $row);

    $a.removeClass('processing');
  });
});
